/* You can add global styles to this file, and also import other style files */
@import url("https://use.typekit.net/nan5blu.css");

* {
  font-family: "aktiv-grotesk", sans-serif;
  // outline: 1px solid lightcoral;
}

// html,
// body {
//   height: 100%;
// }

// body {
//   overflow: auto;
// }
html,
body {
  overflow-x: hidden; /* Hides the horizontal scrollbar */
}

.rounded2 {
  border-radius: 2px;
}
.rounded4 {
  border-radius: 4px;
}

.rounded5 {
  border-radius: 5px;
}
.rounded6 {
  border-radius: 6px;
}
.rounded7 {
  border-radius: 7px;
}
.rounded8 {
  border-radius: 8px;
}
.rounded9 {
  border-radius: 9px;
}
.rounded10 {
  border-radius: 10px;
}
.rounded11 {
  border-radius: 11px;
}
.rounded12 {
  border-radius: 12px;
}
.rounded13 {
  border-radius: 13px;
}
.rounded14 {
  border-radius: 14px;
}
.rounded15 {
  border-radius: 15px;
}
.rounded16 {
  border-radius: 16px;
}
.rounded17 {
  border-radius: 17px;
}
.rounded18 {
  border-radius: 18px;
}
.rounded19 {
  border-radius: 19px;
}
.rounded20 {
  border-radius: 20px;
}
.rounded22 {
  border-radius: 22px;
}

.fw200 {
  font-weight: 200;
}

.fw350 {
  font-weight: 350;
}
.fw400 {
  font-weight: 400;
}
.fw500 {
  font-weight: 500;
}
.fw550 {
  font-weight: 550;
}
.fw600 {
  font-weight: 600;
}
.fw700 {
  font-weight: 700;
}
.fw800 {
  font-weight: 800;
}
.fw900 {
  font-weight: 900;
}

.font11 {
  font-size: 11px;
}
.font12 {
  font-size: 12px;
}
.font13 {
  font-size: 13px;
}
.font14 {
  font-size: 14px;
}
.font15 {
  font-size: 15px;
}
.font16 {
  font-size: 16px;
}
.font18 {
  font-size: 18px;
}
.font20 {
  font-size: 20px;
}
.font22 {
  font-size: 22px;
}
.font24 {
  font-size: 24px;
}
.font26 {
  font-size: 26px;
}
.font28 {
  font-size: 28px;
}

.font30 {
  font-size: 30px;
}
.font32 {
  font-size: 32px;
}
.font36 {
  font-size: 36px;
}
.font40 {
  font-size: 40px;
}
.font48 {
  font-size: 48px;
}
.font50 {
  font-size: 50px;
}
.font64 {
  font-size: 64px;
}

.font128 {
  font-size: 128px;
  list-style: 77px;
}

.lh17 {
  line-height: 17px;
}
.lh20 {
  line-height: 20px;
}
.lh25 {
  line-height: 25px;
}
.lh35 {
  line-height: 35px;
}
.lh40 {
  line-height: 40px;
}
.lh45 {
  line-height: 45px;
}
.lh77 {
  line-height: 77px;
}
.lh80 {
  line-height: 80px;
}
.lh100 {
  line-height: 100px;
}
.text-center {
  text-align: center;
}
.text-center-1 {
  text-align: start;
}
.grey-bg {
  background-color: #414040;
  z-index: 2;
}
.bg-light-grey {
  background-color: rgba(217, 217, 217, 0.21);
}
.bg-light-grey-1 {
  background: rgba(217, 217, 217, 0.55);
}
.bg-light-yellow {
  background-color: rgba(251, 176, 46, 0.1);
}
.bg-lightest-yellow {
  background-color: rgba(151, 150, 150, 0.21);
}
.orange-bg {
  background-color: #fbb02e;
}
.orange-primary {
  background-color: #ea5c24;
}
.font-primary {
  color: #ea5c24 !important;
}
.font-black {
  color: black;
}
.text-brown {
  color: #414040;
}
.text-light-brown {
  color: #645e5e;
}
.text-light-grey {
  color: #444342;
}
.text-yellow {
  color: #fbb02e;
}
.text-light-black {
  color: #0000004d;
}
.text-grey {
  color: #979696;
}
// .text-white {
//   color: #ffffff;
// }
.outline-numbers {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  // font-family: "Anek Malayalam";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 39.063% */
}
.bgimage {
  background-image: url(../src/assets/bgimage.webp);
  background-color: #414040fc;
  background-repeat: repeat-x;
  background-blend-mode: hue;
  background-size: contain;
}
.bg-grey {
  background-color: #414040;
}
.bg-light-grey-1 {
  background-color: #00000007;
}
.bgwhite {
  background: #fff;
}
.bgwhiteshadow {
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.bgorangeshadow {
  border-radius: 0px 0px 7px 7px;
  background: #ea5c24;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.bgorange {
  // border-radius: 0px 0px 7px 7px;
  background: #ea5c24;
  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.bgyellow {
  background-color: #fbb02e;
}
.bgimage-1 {
  // position: relative;
  background-image: url(../src/assets/bgimageorange.jpg);
  background-color: #ea5c24;
  background-repeat: repeat-x;
  background-blend-mode: color-burn;
  background-size: contain;
  z-index: 2;
}
.orangecircle {
  min-width: 58px;
  min-height: 58px;
  flex-shrink: 0;
  background-color: #fbb02e;
  border-radius: 50%;
}
.darkorangecircle {
  width: 104px;
  height: 104px;
  flex-shrink: 0;
  background-color: #ea5c24;
  border-radius: 50%;
}

.buy-now-btn {
  background-color: #ea5c24;
  border: none;
  padding: 5px;
  color: white;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 11px;
  transition: background-color 0.3s;
}

.buy-now-btn:hover {
  background-color: #c0392b;
}

.add-to-cart-btn {
  background-color: #414040;
  border: none;
  padding: 5px;
  color: white;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 11px;
  transition: background-color 0.3s;
}

.add-to-cart-btn:hover {
  background-color: #222;
}

.bordernone {
  border: none;
}
@media screen and (max-width: 600px) {
  .text-center-1 {
    text-align: center;
  }
}
.products-container {
  gap: 25px;
}

.product-card {
  width: 301px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #f4f4f4;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
  // font-family: ;
}

.product-card-header {
  position: relative;
}

.product-image {
  width: 231px;
  height: 203px;
  flex-shrink: 0;
  margin-bottom: 10px;
  margin-right: 5px;
  margin-top: 10px;
  object-fit: contain;
}

.wishlist-btn {
  background: none;
  border: none;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

.wishlist-btn img {
  width: 20px;
  height: 20px;
}

.product-details {
  margin-bottom: 15px;
}

.product-name {
  font-size: 20px !important;
  font-weight: 400;
  margin: 5px 0;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}
.product-name-sale {
  font-size: 15px !important;
  font-weight: 400;
  margin: 5px 0;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}

.product-price {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}

.original-price {
  text-decoration: line-through;
  color: #888;
  margin-right: 5px;
  font-size: 12px;
  font-weight: 400;
}

.discounted-price {
  font-weight: bold;
  color: #e74c3c;
  font-size: 15px;
  font-weight: 400;
  color: #000;
}

.product-rating {
  color: #f39c12;
  font-size: 14px;
}

.product-card-footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.search-bg {
  // border-radius: 12px;
  background: #ececec;
  border: none;
}
.search-image {
  margin-left: -2.5rem;
  z-index: 10;
  padding: 10px;
  background-color: #d2d2d2;
  border-radius: 0 12px 12px 0;
}

.font-strike {
  text-decoration: line-through !important;
}

.text-light-blue {
  color: #5a6063;
}

@media screen and (max-width: 600px) {
  .font128 {
    font-size: 100px !important;
  }
  .lh80 {
    line-height: 70px !important;
  }
}
.border-black {
  border: 1px solid black;
}
.transparent-button {
  background-color: transparent !important;
  border: none !important;
  font-size: 24px;
  color: #c0392b;
}
.transparent-button:disabled {
  color: #414040;
  cursor: not-allowed;
  display: none;
}
.cartButton {
  background-color: #fbb02e;
  color: white;
  border-radius: 4px;
  border: none;
}
.buyNowButton {
  background-color: #ea5c24;
  color: white;
  border-radius: 4px;
  border: none;
}
.cartButtonOrange {
  background-color: #ea5c24;
  color: white;
  border-radius: 12px;
  border: none;
}

.buyNowButton:disabled {
  background-color: #895f4e;
  color: white;
  border-radius: 4px;
  border: none;
  cursor: not-allowed;
}
.bg-transparent {
  background-color: transparent;
}
.border-grey {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.rating-stars {
  display: flex;
}

.star {
  font-size: 18px; /* Adjust size as needed */
  color: lightgray; /* Color for unfilled stars */
}

.star.filled {
  color: #fbb02e; /* Color for filled stars */
}
.star.half-filled {
  background: linear-gradient(to right, #fbb02e 50%, lightgray 50%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.border-top-grey {
  border-top: 1px solid #ededed;
}

button:disabled {
  color: white;
  background-color: #414040;
}

.two-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 54px; /* Adjust based on your line height */
  line-height: 27px; /* Adjust to match your font size and layout */
}
.index-page {
  background-color: #d9d9d963;
  color: black;
  border-radius: 100%;
  //  padding: 7px;
  font-size: 14px;
  min-width: 28px !important;
  min-height: 28px !important;
  display: grid;
  align-items: center;
  text-align: center;
}
// .active-index {
//   background-color: black;
//   color: white;
.calendly-inline-widget,
.calendly-inline-widget *,
.calendly-badge-widget,
.calendly-badge-widget *,
.calendly-overlay,
.calendly-overlay * {
  font-size: 16px;
  line-height: 1.2em;
}

.calendly-inline-widget iframe,
.calendly-badge-widget iframe,
.calendly-overlay iframe {
  display: inline;
  width: 100%;
  height: 100%;
}

.calendly-popup-content {
  position: relative;
}

.calendly-popup-content.calendly-mobile {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.calendly-overlay {
  position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  inset: 0;
  // overflow: hidden;
  z-index: 9999;
  // background-color: #a5a5a5;
  background-color: rgba(31, 31, 31, 0.4);
}

.calendly-overlay .calendly-close-overlay {
  position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  inset: 0;
}

.calendly-overlay .calendly-popup {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  // -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translate(-50%);
  width: 80%;
  min-width: 900px;
  max-width: 1000px;
  height: 90%;
  max-height: 680px;
}

@media (max-width: 975px) {
  .calendly-overlay .calendly-popup {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: none;
    transform: none;
    width: 100%;
    height: auto;
    min-width: 0;
    max-height: none;
  }
}

.calendly-overlay .calendly-popup .calendly-popup-content {
  height: 100%;
}

.calendly-overlay .calendly-popup-close {
  position: absolute;
  top: 25px;
  right: 25px;
  color: #fff;
  width: 19px;
  height: 19px;
  cursor: pointer;
  background: url("../src/assets/calendly-close-icon.svg") no-repeat;
  background-size: contain;
}

@media (max-width: 975px) {
  .calendly-overlay .calendly-popup-close {
    top: 15px;
    right: 15px;
  }
}

.calendly-badge-widget {
  position: fixed;
  right: 20px;
  bottom: 15px;
  z-index: 9998;
}

.calendly-badge-widget .calendly-badge-content {
  display: table-cell;
  width: auto;
  height: 45px;
  padding: 0 30px;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 2px 5px;
  font-family: sans-serif;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.calendly-badge-widget .calendly-badge-content.calendly-white {
  color: #666a73;
}

.calendly-badge-widget .calendly-badge-content span {
  display: block;
  font-size: 12px;
}

.calendly-spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  z-index: -1;
}

.calendly-spinner > div {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #e1e1e1;
  border-radius: 50%;
  vertical-align: middle;
  -webkit-animation: calendly-bouncedelay 1.4s infinite ease-in-out;
  animation: calendly-bouncedelay 1.4s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.calendly-spinner .calendly-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.calendly-spinner .calendly-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

// @-webkit-keyframes calendly-bouncedelay {
//   0%,
//   80%,
//   100% {
//     -webkit-transform: scale(0);
//     transform: scale(0);
//   }

//   40% {
//     -webkit-transform: scale(1);
//     transform: scale(1);
//   }
// }

// @keyframes calendly-bouncedelay {
//   0%,
//   80%,
//   100% {
//     -webkit-transform: scale(0);
//     transform: scale(0);
//   }

//   40% {
//     -webkit-transform: scale(1);
//     transform: scale(1);
//   }
// }
.calendly-overlay iframe {
  height: 120% !important;
  margin-top: -80px !important;
}
// }
.gm-style-iw-chr {
  display: none !important;
}

.gm-style .gm-style-iw-c {
  background-color: #333 !important;
  // z-index: 100;
}
.gm-style-iw-d {
  background-color: #333 !important;
}
.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece {
  background-color: #333 !important;
}

// .gm-style .gm-style-iw-tc {
//   background-color: #333 !important;
// }
.gm-style .gm-style-iw-tc::after {
  background-color: #333 !important;
  -webkit-clip-path: polygon(50% 0, 0% 100%, 0% 0%);
  clip-path: polygon(50% 0, 0% 100%, 0% 0%);
  left: 100%;
  min-height: 50px;
  min-width: 50px;
  margin-bottom: 10px !important;
}
.hovertorange:hover {
  color: #e74c3c !important;
}
.active-index {
  filter: invert(1);
}
.initcap {
  text-transform: capitalize;
}

/* For Chrome, Edge, and Safari */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
